import { Map, LatLng } from "leaflet";
export const SET_SHOP_PREFERENCES = "SET_SHOP_PREFERENCES";
export const SET_INITIAL_DELIVERY_POINTS = "SET_INITIAL_DELIVERY_POINTS";
export const SET_AVAILABLE_DELIVERY_POINTS = "SET_AVAILABLE_DELIVERY_POINTS";
export const DELETE_DELIVERY_POINT = "DELETE_DELIVERY_POINT";
export const SET_FETCHING = "SET_FETCHING";
export const SET_SELECTED_POINT = "SET_SELECTED_POINT";
export const SET_MAP = "SET_MAP";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_PERMISSION_ERROR = "SET_PERMISSION_ERROR";
export const SET_FILTERS = "SET_FILTERS";
export const SET_CENTER = "SET_CENTER";
export const SET_API_ERROR = "SET_API_ERROR";

export type Filters = {
  saturdayOpen: boolean;
  sundayOpen: boolean;
  wheelChairAccessible: boolean;
  availableCapacity: boolean;
  payByCard: boolean;
  searchParam: string | null;
  connectorTypes: string[];
};

export type Preferences = {
  logo: string;
  lang: "en" | "ro";
  showAvailabilityLabel: boolean;
  showSaturdayOpenFilter: boolean;
  showSundayOpenFilter: boolean;
  showPayByCardFilter: boolean;
  showWheelChairAccessibleFilter: boolean;
  showAvailableCapacityFilter: boolean;
  showCompanies: string[];
};
export interface ICollectPoint {
  id: string;
  active: boolean;
  address1: string;
  address2: string;
  city: string;
  country: string | null;
  county: string | null;
  countyCode: string | null;
  description: string;
  distance: string;
  latitude: string;
  locationType: string;
  longitude: string;
  merchantConnectorId: number;
  name: string;
  phoneNumber: string;
  shopifyLocationId: number;
  street: string | null;
  mondaySchedule: string | null;
  tuesdaySchedule: string | null;
  wednesdaySchedule: string | null;
  thursdaySchedule: string | null;
  fridaySchedule: string | null;
  saturdaySchedule: string | null;
  sundaySchedule: string | null;
  zipCode: string;
  payByCard: boolean;
  wheelChairAccessible: boolean;
  availableCapacity: boolean;
  connectorType: string;
  originalConnectorType: string;
}

type SetShopPreferences = {
  type: typeof SET_SHOP_PREFERENCES;
  payload: Preferences;
};

type SetFetchingAction = {
  type: typeof SET_FETCHING;
  payload: boolean;
};

type SetInitialPointsListAction = {
  type: typeof SET_INITIAL_DELIVERY_POINTS;
  payload: ICollectPoint[];
};

type SetAvailablePointsListAction = {
  type: typeof SET_AVAILABLE_DELIVERY_POINTS;
  payload: ICollectPoint[];
};

type DeleteAction = {
  type: typeof DELETE_DELIVERY_POINT;
  payload: ICollectPoint;
};

type SetSelectedPoint = {
  type: typeof SET_SELECTED_POINT;
  payload: ICollectPoint | null;
};

type SetMap = {
  type: typeof SET_MAP;
  payload: Map;
};

type SetUserLocation = {
  type: typeof SET_USER_LOCATION;
  payload: LatLng;
};

type SetFilters = {
  type: typeof SET_FILTERS;
  payload: Filters;
};

type SetCenter = {
  type: typeof SET_CENTER;
  payload: LatLng;
};

type SetApiError = {
  type: typeof SET_API_ERROR;
  payload: string;
};

type SetPermissionError = {
  type: typeof SET_PERMISSION_ERROR;
  payload: GeolocationPositionError;
};

export type Action =
  | SetShopPreferences
  | SetInitialPointsListAction
  | SetAvailablePointsListAction
  | DeleteAction
  | SetFetchingAction
  | SetSelectedPoint
  | SetMap
  | SetUserLocation
  | SetFilters
  | SetCenter
  | SetPermissionError
  | SetApiError;

export type DispatchType = (args: Action) => Action;

export type State = {
  preferences: Preferences | null;
  initialPointsList: ICollectPoint[];
  availablePoints: ICollectPoint[];
  filters: Filters;
  isFetching: boolean;
  apiError: string | null;
  permissionError: GeolocationPositionError | null;
  selectedPoint: ICollectPoint | null;
  mapInstance: Map | null;
  userLocation: LatLng | null;
};
