import * as React from "react";
import { ICollectPoint, Preferences, State } from "../../types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ScheduleItem } from "./ScheduleItem";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import AccessibleIcon from "@mui/icons-material/Accessible";
import "./MapFooter.css";
import {
  brtLogo,
  cargusLogo,
  dpdLogo,
  econtLogo,
  fanCourierLogo,
  glsLogo,
  innoshipLogo,
  packeteryLogo,
  pplLogo,
  sameDayLogo,
  speedyLogo,
} from "../Map/markers";
import { CardIcon } from "../Icons/Card";
import { CloseIcon } from "../Icons/CloseIcon";
import { setSelectedPoint } from "../../store/actions";
import { Dispatch } from "redux";

type Props = {
  selectedPoint: ICollectPoint | null;
};
const isPointAvailable = (selectedPoint: ICollectPoint) => {
  return selectedPoint.mondaySchedule ||
    selectedPoint.thursdaySchedule ||
    selectedPoint.wednesdaySchedule ||
    selectedPoint.thursdaySchedule ||
    selectedPoint.fridaySchedule ||
    selectedPoint.saturdaySchedule ||
    selectedPoint.sundaySchedule ||
    selectedPoint.availableCapacity
    ? true
    : false;
};
export const MapFooter: React.FC<Props> = ({ selectedPoint }) => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const preferences: Preferences | null = useSelector(
    (state: State) => state.preferences,
    shallowEqual
  );
  const isMobile = document.body.clientWidth <= 1123;

  const getShortHours = () => {
    let data = [
      { day: t("Monday"), schedule: selectedPoint?.mondaySchedule },
      { day: t("Tuesday"), schedule: selectedPoint?.tuesdaySchedule },
      { day: t("Wednesday"), schedule: selectedPoint?.wednesdaySchedule },
      { day: t("Thursday"), schedule: selectedPoint?.thursdaySchedule },
      { day: t("Friday"), schedule: selectedPoint?.fridaySchedule },
      { day: t("Saturday"), schedule: selectedPoint?.saturdaySchedule },
      { day: t("Sunday"), schedule: selectedPoint?.sundaySchedule },
    ];

    let merged = data.reduce(function (preVal: any, curVal: any) {
      let last: any = preVal[preVal.length - 1];

      if (last && last.schedule === curVal.schedule) {
        last.endDay = curVal.day;
      } else {
        preVal.push(curVal);
      }

      return preVal;
    }, []);

    let mergedNewFormat = merged.map(function (v: any) {
      return {
        days: {
          start: v.endDay ? v.day.slice(0, 3) : v.day,
          final: v.endDay ? v.endDay.slice(0, 3) : null,
        },
        hours: v.schedule,
      };
    });

    return mergedNewFormat;
  };

  const getIcon = (connectorType: string) => {
    let logo = { icon: "", title: "" };

    if (connectorType) {
      if (connectorType === "SAMEDAY")
        logo = { icon: sameDayLogo, title: "Sameday" };
      if (connectorType === "FAN_COURIER")
        logo = { icon: fanCourierLogo, title: "FAN" };
      if (connectorType.includes("GLS")) logo = { icon: glsLogo, title: "GLS" };
      if (connectorType.includes("PPL")) logo = { icon: pplLogo, title: "PPL" };
      if (connectorType.includes("DPD")) logo = { icon: dpdLogo, title: "DPD" };
      if (connectorType === "PACKETERY")
        logo = { icon: packeteryLogo, title: "PACKETA" };
      if (connectorType === "URGENT_CARGUS")
        logo = { icon: cargusLogo, title: "Cargus" };
      if (connectorType === "ECONT") logo = { icon: econtLogo, title: "Econt" };
      if (connectorType === "INNOSHIP")
        logo = { icon: innoshipLogo, title: "Innoship" };
      if (connectorType.includes("BRT")) logo = { icon: brtLogo, title: "BRT" };
      if (connectorType.includes("SPEEDY"))
        logo = { icon: speedyLogo, title: "Speedy" };
    }

    return logo;
  };

  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint | null) =>
      dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );

  return (
    <React.Fragment>
      {selectedPoint ? (
        <Box
          // container
          // spacing={2}
          alignItems={"center"}
          className="point-details"
          sx={{
            // p: 1,
            justifyContent: "space-between",
            maxHeight: isMobile ? "380px" : "initial",
            // justifyContent: "space-between",
            "-webkit-mask-image": isMobile
              ? "linear-gradient(to bottom, black 75%, transparent 100%)"
              : "initial",
          }}
        >
          <Grid
            item
            container
            height={"max-content"}
            className={"point-details-column"}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                flexBasis: isMobile ? "100%" : "99%",
                maxWidth: isMobile ? "100%" : "99%",
              }}
            >
              <Box
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  paddingBottom: isMobile ? "5px" : "0",
                  paddingLeft: isMobile ? "5px" : "0",
                  paddingTop: isMobile ? "5px" : "0",
                }}
              >
                <div
                  className="mobile-icon"
                  key={selectedPoint.connectorType}
                  dangerouslySetInnerHTML={{
                    __html: getIcon(selectedPoint.originalConnectorType || selectedPoint.connectorType).icon,
                  }}
                />
                <Typography variant="body1" className="point-details-title">
                  {selectedPoint.name}
                </Typography>
                {isMobile && (
                  <Typography variant="subtitle2" className="point-distance">
                    {selectedPoint?.distance
                      ? `${selectedPoint.distance}km`
                      : ""}
                  </Typography>
                )}
              </Box>

              <Typography variant="body1" className="point-details-address">
                {selectedPoint.address1}
              </Typography>

              <Typography
                variant="subtitle2"
                className="point-details-card"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <CardIcon style={{ padding: "0 2px" }} />
                {selectedPoint.payByCard
                  ? t("Possibility to pay by card")
                  : t("Not possible to pay by card at this pick-up point")}
              </Typography>
              {selectedPoint.wheelChairAccessible && (
                <Typography
                  variant="subtitle2"
                  className="point-details-card"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <AccessibleIcon />
                  {t("Wheelchair access")}
                </Typography>
              )}
            </Grid>{" "}
            {!isMobile && (
              <Divider
                orientation="vertical"
                className="point-details-divider"
              />
            )}
          </Grid>
          <Grid
            item
            style={{
              paddingLeft: 0,
              minWidth: isMobile ? "100%" : "200px",
              maxWidth: isMobile ? "100%" : "200px",
            }}
          >
            {preferences &&
            preferences.showAvailabilityLabel &&
            !isPointAvailable(selectedPoint) ? (
              <Typography variant="h5" color="error">
                {t("Pick-up point not available")}
              </Typography>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                className={"opening-hours"}
              >
                <Typography
                  variant="body1"
                  fontWeight={700}
                  style={{
                    paddingBottom: "7px",
                    fontSize: isMobile ? "16px" : "18px",
                  }}
                >
                  <strong>{t("Opening hours")}:</strong>
                </Typography>
                {getShortHours()?.map((hours: any) => (
                  <ScheduleItem
                    dayLabel={
                      hours.days.start +
                      (hours.days.final ? " - " + hours.days.final : "")
                    }
                    scheduleValue={hours.hours}
                  ></ScheduleItem>
                ))}
              </Box>
            )}
          </Grid>
          {!isMobile && <CloseIcon onClick={() => selectPoint(null)} />}{" "}
        </Box>
      ) : null}
    </React.Fragment>
  );
};
