import * as React from "react";
import { ICollectPoint, State } from "../../types";
import { setSelectedPoint } from "../../store/actions";
import { Dispatch } from "redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Skeleton,
  Theme,
  Divider,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import pointSkeleton from "./pointSkeleton.json";
import {
  arrow,
  arrowBottom,
  brtLogo,
  cargusLogo,
  dpdLogo,
  econtLogo,
  fanCourierLogo,
  glsLogo,
  innoshipLogo,
  packeteryLogo,
  pplLogo,
  sameDayLogo,
  speedyLogo,
} from "../Map/markers";

type Props = {
  points: ICollectPoint[];
  selectedPoint: ICollectPoint | null;
  focusedSearch: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiListItemSecondaryAction-root": {
        right: 4,
      },
    },
  })
);

const PointsList: React.FC<Props> = ({
  points,
  selectedPoint,
  focusedSearch,
}) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [openedList, setOpenedList] = React.useState(false);
  const selectPoint = React.useCallback(
    (selectedPoint: ICollectPoint) => dispatch(setSelectedPoint(selectedPoint)),
    [dispatch]
  );

  const isFetching = useSelector(
    (state: State) => state.isFetching,
    shallowEqual
  );

  const searchParam = useSelector(
    (state: State) => state.filters.searchParam,
    shallowEqual
  );

  const availablePoints: ICollectPoint[] | null = useSelector(
    (state: State) => state.availablePoints,
    shallowEqual
  );

  const getIcon = (connectorType: string) => {
    let logo = { icon: "", title: "" };

    if (connectorType) {
      if (connectorType === "SAMEDAY")
        logo = { icon: sameDayLogo, title: "Sameday" };
      if (connectorType === "FAN_COURIER")
        logo = { icon: fanCourierLogo, title: "FAN" };
      if (connectorType.includes("GLS")) logo = { icon: glsLogo, title: "GLS" };
      if (connectorType.includes("PPL")) logo = { icon: pplLogo, title: "PPL" };
      if (connectorType.includes("DPD")) logo = { icon: dpdLogo, title: "DPD" };
      if (connectorType === "PACKETERY")
        logo = { icon: packeteryLogo, title: "PACKETA" };
      if (connectorType === "URGENT_CARGUS")
        logo = { icon: cargusLogo, title: "Cargus" };
      if (connectorType === "ECONT") logo = { icon: econtLogo, title: "Econt" };
      if (connectorType === "INNOSHIP")
        logo = { icon: innoshipLogo, title: "Innoship" };
      if (connectorType.includes("BRT")) logo = { icon: brtLogo, title: "BRT" };
      if (connectorType.includes("SPEEDY"))
        logo = { icon: speedyLogo, title: "Speedy" };
    }

    return logo;
  };

  const memoizedList = React.useMemo(
    () =>
      (!isFetching ? points : Array(20).fill(pointSkeleton)).map(
        (point, index) => (
          <Box
            key={index}
            className={`box-item 
          ${selectedPoint && selectedPoint?.id === point?.id ? "selected" : ""}
         `}
          >
            <ListItem
              className="list-item"
              disablePadding
              autoFocus={
                selectedPoint && selectedPoint?.id === point?.id ? true : false
              }
              onClick={() => {
                selectPoint(point);
                if (!searchParam) {
                  document.querySelector("#available-points")!.scrollTop = 0;
                }
              }}
            >
              <ListItemButton
                className={`list-item-button 
               ${
                 selectedPoint && selectedPoint?.id === point?.id
                   ? "selected"
                   : ""
               }
              `}
              >
                <Box
                  className="logo"
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <div
                    key={point.connectorType}
                    dangerouslySetInnerHTML={{
                      __html: getIcon(point.originalConnectorType || point.connectorType).icon,
                    }}
                  />
                  {!isFetching ? (
                    <Typography className="logo-text">
                      {getIcon(point.originalConnectorType || point.connectorType).title}
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="text"
                      width={`${60 + Math.random() * 20}%`}
                    />
                  )}
                </Box>
                <Divider orientation="vertical" flexItem className="divider" />
                <ListItemText
                  primary={
                    !isFetching ? (
                      <>
                        <Typography className="point-title">
                          {point?.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="point-distance"
                        >
                          {point?.distance ? `${point.distance}km` : ""}
                        </Typography>
                      </>
                    ) : (
                      <Skeleton
                        variant="text"
                        width={`${60 + Math.random() * 20}%`}
                      />
                    )
                  }
                  secondary={
                    !isFetching ? (
                      <Typography className="point-address">
                        {point?.address1}
                      </Typography>
                    ) : (
                      <Skeleton
                        variant="text"
                        width={`${50 + Math.random() * 20}%`}
                      />
                    )
                  }
                />
              </ListItemButton>
            </ListItem>

            <Divider
              className={`divider-bottom ${
                selectedPoint?.id !== point.id ? "" : "hide"
              }`}
            />
          </Box>
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, points, selectedPoint, searchParam, availablePoints]
  );

  if (!isFetching && !points.length) return <></>;
  return (
    <>
      <Box
        className={`opened-toggle ${openedList ? "opened" : ""} ${
          focusedSearch ? "focused" : ""
        }`}
        onClick={() => setOpenedList(!openedList)}
      >
        <div
          className="opened-icon"
          dangerouslySetInnerHTML={{
            __html: openedList ? arrowBottom : arrow,
          }}
        />
      </Box>
      <List
        className={`${classes.root} list ${openedList ? "opened" : ""} ${
          focusedSearch ? "focused" : ""
        }`}
        component="nav"
        id="available-points"
        aria-label="main mailbox folders"
        sx={{
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        {memoizedList}
      </List>{" "}
    </>
  );
};

export default React.memo(PointsList);
